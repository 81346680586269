<template>
    <v-dialog
        v-model="isOpen"
        content-class="v-application v-application--is-ltr"
        max-width="800px"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
    >
        <v-card
            :loading="isLoading"
            :disabled="isLoading"
            class="overflow-auto"
        >
            <v-card-title
                color="primary"
                class="text-h5"
                dark
            >
                verfügbare Freiberufler für den {{ date }}
            </v-card-title>

            <v-toolbar
                color="secondary"
                dark
            >
                <v-row fluid>
                    <v-col cols="6">
                        <div class="text-h6">
                            Apotheken Info
                        </div>
                        <div>
                            WWS: {{ pharmacy.erp }}
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <div class="text-h6">
                            Termin Info
                        </div>
                        <div>
                            HBA: {{ displayHbaText }}
                        </div>
                    </v-col>
                </v-row>
            </v-toolbar>
            <NoApprovalAlert v-if="!pharmacy.no_approval_required" />
            <v-card-text class="py-0">
                <v-row fluid align="center" class="text-center ma-0">
                    <v-col cols="4">
                        <v-checkbox
                            v-model="onlyAvailable"
                            label="Nur verfügbare"
                            color="primary"
                            hide-details
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            v-model="maxDistance"
                            label="Max. Entfernung"
                            color="primary"
                            class="max-distance"
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            v-model="erpLevel"
                            type="number"
                            min="0"
                            max="3"
                            :rules="[val => (val > -1 && val < 4) || 'Nummer ist nicht zwischen 0 und 3']"
                            label="WWS min. Erfahrung (0-3)"
                            color="primary"
                            class="min-erp-level"
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-checkbox
                            v-model="hasWorkedInPharmacy"
                            label="Hat schon dort gearbeitet"
                            color="primary"
                            hide-details
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="4">
                        <v-checkbox
                            v-model="hasHba"
                            label="Hat HBA"
                            color="primary"
                            hide-details
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="4" class="align-self-end">
                        <v-btn @click="getAvailablePharmacists">Filter anwenden</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider class="mt-2" />

            <v-card-text class="py-0">
                <v-row class="available-pharmacists py-4" v-if="availableUsers.length">
                    <v-col cols="7" class="scrollable-list pr-0 pt-0">
                        <v-row class="select-all" no-gutters>
                            <v-col cols="auto">
                                <v-checkbox
                                    v-model="selectedAll"
                                    label="alle auswählen"
                                    class="mt-0 pt-0"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-list min-width="400">
                            <v-list-item-group
                                v-model="selectedListindexes"
                                multiple
                                color="primary"
                            >
                                <v-list-item
                                    v-for="(user, i) in availableUsers"
                                    :key="i"
                                    :data-userid="user.id"
                                    :data-busy="user.busy"
                                    :data-known="user.known"
                                >
                                    <template v-slot:default="{ active }">
                                        <v-list-item-content>
                                            <v-list-item-title :class="{'busy': user.busy }">
                                                <v-icon v-if="user.known" small>mdi-thumb-up-outline</v-icon>
                                                {{ user.lastname }}, {{ user.firstname }}
                                                <span class="font-weight-light">(Luftlinie: {{ Math.round(user.distance) }} km)</span>
                                                <v-icon v-if="user.proposed" color="green" small>mdi-human-greeting-variant</v-icon>
                                                <v-tooltip
                                                    v-if="user.proposal_preference === 'no_auto_proposals'"
                                                    top
                                                    class="no_auto_proposals"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon
                                                            v-on="on"
                                                            color="red"
                                                        >
                                                            mdi-alert-octagon
                                                        </v-icon>
                                                    </template>
                                                    Der Freiberufler möchte keine Terminvorschläge erhalten!
                                                </v-tooltip>
                                                <v-tooltip
                                                    v-if="user.proposal_preference === 'available_dates_only'"
                                                    top
                                                    class="available_dates_only"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon
                                                            v-on="on"
                                                            color="orange"
                                                        >
                                                            mdi-calendar-alert
                                                        </v-icon>
                                                    </template>
                                                    Der Freiberufler möchte nur Terminvorschläge an verfügbaren Tagen erhalten!
                                                </v-tooltip>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <v-chip
                                                    v-for="service in user.display_services_full"
                                                    :color='service.color'
                                                    class="mr-1 px-2"
                                                    dark
                                                    x-small
                                                    label
                                                >
                                                    <b>{{ service.title }}</b>
                                                </v-chip>
                                                <v-chip
                                                    v-if="user.has_hba"
                                                    color='purple'
                                                    class="px-2"
                                                    tag="b"
                                                    dark
                                                    x-small
                                                    label
                                                    outlined
                                                >
                                                    HBA
                                                </v-chip>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-checkbox :input-value="active"></v-checkbox>
                                        </v-list-item-action>
                                    </template>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-col>

                    <v-col cols="5" class="scrollable-list text-center">
                        <div v-for="(user, idx) in selectedUsers">
                            <h4 class="d-inline-block">{{ user.data.firstname }} {{ user.data.lastname }}</h4>
                            <a
                                :href="`/pharmacists/${user.data._id}`"
                                target="_blank">
                                    anzeigen
                            </a>
                            |
                            <a
                                :href="user.navigationURL"
                                target="_blank">
                                    Maps
                            </a>
                            <br>
                            <span>{{pharmacy.erp}} Kenntnisse: {{ user.erpExpertise }}/3</span>
                            <br>
                            <div v-if="user.data.proposed" class="pb-2">
                                <span>Vorgeschlagen: {{ formatDate(user.data.proposed_date) }}</span>
                                <br>
                                <v-btn
                                    small
                                    rounded
                                    text
                                    outlined
                                    @click="unproposePharmacist(user)"
                                    :disabled="isLoading"
                                    class="my-1"
                                >
                                    Vorschlag zurücknehmen
                                </v-btn>
                            </div>
                            <vc-calendar
                                :columns="$screens({ default: 1, lg: 1 })"
                                :attributes="user.attributes"
                                :from-page="appointmentDate"
                            />
                            <v-divider
                                v-if="idx + 1 < selectedUsers.length"
                                class="my-4"
                            ></v-divider>
                        </div>
                    </v-col>
                </v-row>
                <h4 class="my-5" v-else-if="!availableUsers.length && !isLoading"> keine Ergebnisse. </h4>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="justify-end">
                <v-btn
                    color="secondary"
                    text
                    @click="isOpen = false"
                >
                    ohne Auswahl schließen
                </v-btn>
                <v-btn
                    color="primary"
                    @click="onPharmacistSelected"
                    :disabled="selectedUsers.length !== 1 || appointment.wf_state == 'inquired'"
                >
                    Auswählen
                </v-btn>
                <v-btn
                    color="secondary"
                    @click="proposeMultiplePharmacists"
                    :disabled="allPharmacistsProposed || appointment.wf_state == 'inquired' || isLoading || appointment.hidden"
                    class="propose"
                >
                    Termin vorschlagen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
    import axios from 'axios';
    import { mapMutations } from 'vuex';
    import NoApprovalAlert from './NoApprovalAlert.vue';

    export default {
        props: {
            value: Boolean,
            pharmacy: Object,
            appointment: Object,
            services: Array,
            date: String,
            wfState: String
        },
        components:{ NoApprovalAlert },
        data: () => ({
            availableUsers: [],
            selectedUsers: [],
            selectedListindexes: [],
            isLoading: false,
            onlyAvailable: true,
            maxDistance: 100,
            fulfillErp: true,
            erpLevel: 2,
            hasWorkedInPharmacy: false,
            hasHba: true,
        }),
        computed: {
            isOpen: {
                get() {
                    return this.value
                },
                set(state) {
                    this.$emit('input', state)
                }
            },
            appointmentDate() {
                const date = new Date(this.appointment.date);
                return {
                    month: date.getMonth() + 1,
                    year: date.getFullYear()
                }
            },
            displayHbaText() {
                if (this.appointment.hba) {
                    return "HBA erforderlich";
                } else {
                    return "kein HBA erforderlich";
                }
            },
            allPharmacistsProposed() {
                return this.selectedUsers.every(({ data }) => {
                    return data.proposed
                })
            },
            selectedAll: {
                get() {
                    return this.selectedListindexes.length === this.availableUsers.length
                },
                set(state) {
                    if (state) {
                        this.selectedListindexes = this.availableUsers.map((el, idx) => idx)
                    } else this.selectedListindexes = []
                }
            }
        },
        methods: {
            async getAvailablePharmacists() {
                try {
                    this.isLoading = true;
                    let allUsers = []
                    for (const service of this.services) {
                        const response = await axios(`/pharmacies/${this.pharmacy._id}/${this.appointment._id}/available_users`,
                        {
                            params: {
                                service,
                                only_available: this.onlyAvailable,
                                max_distance: this.maxDistance,
                                erp_level: this.erpLevel,
                                has_worked_in_pharmacy: this.hasWorkedInPharmacy,
                                has_hba: this.hasHba,
                                filter: true,
                            }
                        });
                        allUsers = [...allUsers, ...response.data];
                    }
                    this.availableUsers = allUsers;
                } catch (error) {
                    this.alertError(error)
                } finally {
                    this.isLoading = false
                }
            },
            async getPharmacistAvailabilityDates(user) {
                try {
                    const response = await axios(`/pharmacists/${user.id}/details`);
                    if (response.status >= 400) throw new Error();
                    return {
                        availableDates: response.data.available_dates,
                        allocatedDates: response.data.allocated_dates,
                        unavailableDates: response.data.unavailable_dates,
                        availableByRequestDates: response.data.available_by_request_dates,
                    }
                } catch (error) {
                    this.alertError(error)
                }
            },
            attributes({ availableDates, allocatedDates, unavailableDates, availableByRequestDates }) {
                return [
                    {
                        highlight: 'green',
                        dates: [ ...availableDates ],
                    },
                    {
                        highlight: 'orange',
                        dates: [ ...allocatedDates ],
                    },
                    {
                        highlight: 'red',
                        dates: [ ...unavailableDates ],
                    },
                    {
                        highlight: 'blue',
                        dates: [ ...availableByRequestDates ],
                    }

                ]
            },
            navigationURL(user) {
                const params = `origin=${user.location.coordinates[1]},${user.location.coordinates[0]}`+
                `&destination=${this.pharmacy.location.coordinates[1]},${this.pharmacy.location.coordinates[0]}`;
                return `https://www.google.com/maps/dir/?api=1&${params}`
            },
            onPharmacistSelected() {
                const user = this.selectedUsers[0].data
                this.$emit('selected-user', user);
                this.isOpen = false
            },
            async proposeMultiplePharmacists() {
                const unproposedPharmacists = this.selectedUsers.filter(pharmacist => {
                    return !pharmacist.data.proposed
                })
                try {
                    this.isLoading = true;
                    await Promise.all(
                        unproposedPharmacists.map(pharmacist => {
                            axios.post(`/api/appointments/${this.appointment._id}/propose/${pharmacist.data._id}`);
                        })
                    )
                    await this.getAvailablePharmacists();
                    await this.onListUpdate();
                    this.alertSuccess("Der Vorschlag wurde erfolgreich übermittelt!");

                } catch (error) {
                    this.alertError(error)
                } finally {
                    this.isLoading = false;
                }
            },

            async unproposePharmacist(pharmacist) {
                try {
                    this.isLoading = true;
                    await axios.post(`/api/appointments/${this.appointment._id}/unpropose/${pharmacist.data._id}`);
                    await this.getAvailablePharmacists();
                    await this.onListUpdate();
                    this.alertSuccess("Der Vorschlag wurde erfolgreich zurückgenommen!");

                } catch (error) {
                    this.alertError(error)
                } finally {
                    this.isLoading = false;
                }
            },
            erpExpertise(user){
                return user.erp_expertise[this.pharmacy.erp];
            },
            formatDate(isoString) {
                if(!isoString.endsWith("Z")) {  // Python 3.9's date serializing doesn't add the Z for UTC
                    isoString += "Z";
                }
                return moment(isoString).format("YYYY-MM-DD HH:mm:SS")
            },
            async onListUpdate() {
                try {
                    this.isLoading = true;
                    this.selectedUsers = await Promise.all(this.selectedListindexes.map(async index => {
                        const user = this.availableUsers[index];
                        const dates = await this.getPharmacistAvailabilityDates(user);
                        const attributes = this.attributes(dates);
                        const navigationURL = this.navigationURL(user);
                        const erpExpertise = this.erpExpertise(user);
                        return {
                            'data': user,
                            'attributes': attributes,
                            'navigationURL': navigationURL,
                            'erpExpertise': erpExpertise
                        }
                    }))
                } catch (error) {
                    this.alertError(error)
                } finally {
                    this.isLoading = false;
                }
            },
            ...mapMutations({
                alertError: 'alert/error',
                alertSuccess: 'alert/success',
            })
        },
        watch: {
            isOpen(open) {
                if (open && !this.availableUsers.length) {
                    this.hasHba = this.appointment.hba;
                    this.getAvailablePharmacists()
                }
            },
            selectedListindexes() {
                this.onListUpdate()
            }
        },
    }
</script>

<style scoped>
    .busy {
        color: red;
    }
    .scrollable-list {
        overflow-y: auto;
        max-height: 40vh;
    }
    .select-all {
        position: sticky;
        top: 0;
        z-index: 100;
        background: #fff;
        border-bottom: 1px solid #dadada;
        padding-bottom: 4px;
        padding-left: 12px;
    }
</style>