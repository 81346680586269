var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "content-class": "v-application v-application--is-ltr",
        "max-width": "800px",
        fullscreen: !_vm.$vuetify.breakpoint.mdAndUp,
      },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "v-card",
        {
          staticClass: "overflow-auto",
          attrs: { loading: _vm.isLoading, disabled: _vm.isLoading },
        },
        [
          _c(
            "v-card-title",
            { staticClass: "text-h5", attrs: { color: "primary", dark: "" } },
            [
              _vm._v(
                "\n            verfügbare Freiberufler für den " +
                  _vm._s(_vm.date) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "v-toolbar",
            { attrs: { color: "secondary", dark: "" } },
            [
              _c(
                "v-row",
                { attrs: { fluid: "" } },
                [
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _c("div", { staticClass: "text-h6" }, [
                      _vm._v(
                        "\n                        Apotheken Info\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n                        WWS: " +
                          _vm._s(_vm.pharmacy.erp) +
                          "\n                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _c("div", { staticClass: "text-h6" }, [
                      _vm._v(
                        "\n                        Termin Info\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n                        HBA: " +
                          _vm._s(_vm.displayHbaText) +
                          "\n                    "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.pharmacy.no_approval_required ? _c("NoApprovalAlert") : _vm._e(),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "py-0" },
            [
              _c(
                "v-row",
                {
                  staticClass: "text-center ma-0",
                  attrs: { fluid: "", align: "center" },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-checkbox", {
                        attrs: {
                          label: "Nur verfügbare",
                          color: "primary",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.onlyAvailable,
                          callback: function ($$v) {
                            _vm.onlyAvailable = $$v
                          },
                          expression: "onlyAvailable",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-text-field", {
                        staticClass: "max-distance",
                        attrs: {
                          label: "Max. Entfernung",
                          color: "primary",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.maxDistance,
                          callback: function ($$v) {
                            _vm.maxDistance = $$v
                          },
                          expression: "maxDistance",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-text-field", {
                        staticClass: "min-erp-level",
                        attrs: {
                          type: "number",
                          min: "0",
                          max: "3",
                          rules: [
                            (val) =>
                              (val > -1 && val < 4) ||
                              "Nummer ist nicht zwischen 0 und 3",
                          ],
                          label: "WWS min. Erfahrung (0-3)",
                          color: "primary",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.erpLevel,
                          callback: function ($$v) {
                            _vm.erpLevel = $$v
                          },
                          expression: "erpLevel",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-checkbox", {
                        attrs: {
                          label: "Hat schon dort gearbeitet",
                          color: "primary",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.hasWorkedInPharmacy,
                          callback: function ($$v) {
                            _vm.hasWorkedInPharmacy = $$v
                          },
                          expression: "hasWorkedInPharmacy",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-checkbox", {
                        attrs: {
                          label: "Hat HBA",
                          color: "primary",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.hasHba,
                          callback: function ($$v) {
                            _vm.hasHba = $$v
                          },
                          expression: "hasHba",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "align-self-end", attrs: { cols: "4" } },
                    [
                      _c(
                        "v-btn",
                        { on: { click: _vm.getAvailablePharmacists } },
                        [_vm._v("Filter anwenden")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mt-2" }),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "py-0" },
            [
              _vm.availableUsers.length
                ? _c(
                    "v-row",
                    { staticClass: "available-pharmacists py-4" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "scrollable-list pr-0 pt-0",
                          attrs: { cols: "7" },
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "select-all",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0 pt-0",
                                    attrs: {
                                      label: "alle auswählen",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.selectedAll,
                                      callback: function ($$v) {
                                        _vm.selectedAll = $$v
                                      },
                                      expression: "selectedAll",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list",
                            { attrs: { "min-width": "400" } },
                            [
                              _c(
                                "v-list-item-group",
                                {
                                  attrs: { multiple: "", color: "primary" },
                                  model: {
                                    value: _vm.selectedListindexes,
                                    callback: function ($$v) {
                                      _vm.selectedListindexes = $$v
                                    },
                                    expression: "selectedListindexes",
                                  },
                                },
                                _vm._l(_vm.availableUsers, function (user, i) {
                                  return _c("v-list-item", {
                                    key: i,
                                    attrs: {
                                      "data-userid": user.id,
                                      "data-busy": user.busy,
                                      "data-known": user.known,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ active }) {
                                            return [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      class: {
                                                        busy: user.busy,
                                                      },
                                                    },
                                                    [
                                                      user.known
                                                        ? _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-thumb-up-outline"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            user.lastname
                                                          ) +
                                                          ", " +
                                                          _vm._s(
                                                            user.firstname
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-weight-light",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "(Luftlinie: " +
                                                              _vm._s(
                                                                Math.round(
                                                                  user.distance
                                                                )
                                                              ) +
                                                              " km)"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      user.proposed
                                                        ? _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "green",
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-human-greeting-variant"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      user.proposal_preference ===
                                                      "no_auto_proposals"
                                                        ? _c(
                                                            "v-tooltip",
                                                            {
                                                              staticClass:
                                                                "no_auto_proposals",
                                                              attrs: {
                                                                top: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-icon",
                                                                            _vm._g(
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "red",
                                                                                  },
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                        mdi-alert-octagon\n                                                    "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                Der Freiberufler möchte keine Terminvorschläge erhalten!\n                                            "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      user.proposal_preference ===
                                                      "available_dates_only"
                                                        ? _c(
                                                            "v-tooltip",
                                                            {
                                                              staticClass:
                                                                "available_dates_only",
                                                              attrs: {
                                                                top: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-icon",
                                                                            _vm._g(
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "orange",
                                                                                  },
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                        mdi-calendar-alert\n                                                    "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                Der Freiberufler möchte nur Terminvorschläge an verfügbaren Tagen erhalten!\n                                            "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item-subtitle",
                                                    [
                                                      _vm._l(
                                                        user.display_services_full,
                                                        function (service) {
                                                          return _c(
                                                            "v-chip",
                                                            {
                                                              staticClass:
                                                                "mr-1 px-2",
                                                              attrs: {
                                                                color:
                                                                  service.color,
                                                                dark: "",
                                                                "x-small": "",
                                                                label: "",
                                                              },
                                                            },
                                                            [
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    service.title
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      _vm._v(" "),
                                                      user.has_hba
                                                        ? _c(
                                                            "v-chip",
                                                            {
                                                              staticClass:
                                                                "px-2",
                                                              attrs: {
                                                                color: "purple",
                                                                tag: "b",
                                                                dark: "",
                                                                "x-small": "",
                                                                label: "",
                                                                outlined: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                HBA\n                                            "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item-action",
                                                [
                                                  _c("v-checkbox", {
                                                    attrs: {
                                                      "input-value": active,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "scrollable-list text-center",
                          attrs: { cols: "5" },
                        },
                        _vm._l(_vm.selectedUsers, function (user, idx) {
                          return _c(
                            "div",
                            [
                              _c("h4", { staticClass: "d-inline-block" }, [
                                _vm._v(
                                  _vm._s(user.data.firstname) +
                                    " " +
                                    _vm._s(user.data.lastname)
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: `/pharmacists/${user.data._id}`,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                anzeigen\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(
                                "\n                        |\n                        "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: user.navigationURL,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                Maps\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.pharmacy.erp) +
                                    " Kenntnisse: " +
                                    _vm._s(user.erpExpertise) +
                                    "/3"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              user.data.proposed
                                ? _c(
                                    "div",
                                    { staticClass: "pb-2" },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "Vorgeschlagen: " +
                                            _vm._s(
                                              _vm.formatDate(
                                                user.data.proposed_date
                                              )
                                            )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "my-1",
                                          attrs: {
                                            small: "",
                                            rounded: "",
                                            text: "",
                                            outlined: "",
                                            disabled: _vm.isLoading,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.unproposePharmacist(
                                                user
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Vorschlag zurücknehmen\n                            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("vc-calendar", {
                                attrs: {
                                  columns: _vm.$screens({ default: 1, lg: 1 }),
                                  attributes: user.attributes,
                                  "from-page": _vm.appointmentDate,
                                },
                              }),
                              _vm._v(" "),
                              idx + 1 < _vm.selectedUsers.length
                                ? _c("v-divider", { staticClass: "my-4" })
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  )
                : !_vm.availableUsers.length && !_vm.isLoading
                ? _c("h4", { staticClass: "my-5" }, [
                    _vm._v(" keine Ergebnisse. "),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-actions",
            { staticClass: "justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "secondary", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.isOpen = false
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                ohne Auswahl schließen\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    disabled:
                      _vm.selectedUsers.length !== 1 ||
                      _vm.appointment.wf_state == "inquired",
                  },
                  on: { click: _vm.onPharmacistSelected },
                },
                [_vm._v("\n                Auswählen\n            ")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "propose",
                  attrs: {
                    color: "secondary",
                    disabled:
                      _vm.allPharmacistsProposed ||
                      _vm.appointment.wf_state == "inquired" ||
                      _vm.isLoading ||
                      _vm.appointment.hidden,
                  },
                  on: { click: _vm.proposeMultiplePharmacists },
                },
                [_vm._v("\n                Termin vorschlagen\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }